import React from 'react'

function Treeland() {
    return (
        <div className='container' style={{height:'900px', display:'flex', justifyContent:'center', alignItems:'center'}}>
             <div className='treeland_coming_title'>Coming Soon</div>

        </div>
    )
}

export default Treeland