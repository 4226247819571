import React from 'react';
import './Footer.css';
import shivantalogo from '../../Media/shivantalogo.jpg';
import logotwo from '../../Media/logotwo.png';
import highstreet from '../../Media/highstreet.jpg';
import iristwo from '../../Media/iristwo.jpg';
import { useNavigate } from 'react-router-dom';


function Footer() {

    let navigate = useNavigate();

    return (

        <footer className='main_footer'>


            <div className='footer_first_div'>

                <section className='footer_first_section row'>
                    <div className='col-12 col-md-7 footer_first_paragraph'>
                        Subscribe For Latest Updates & Know More About Recent Happenings at Sivaa Lifespaces
                    </div>
                    <div className='col-12 col-md-5 '>
                        <input class="form-control form-control-lg footer_first_field" type="text" placeholder="Enter Your Email" aria-label=".form-control-lg example" />
                    </div>
                </section>

                <section className='row footer_second_section'>
                    <div className='col-12 col-md-4 text-center'>
                        <img src={shivantalogo} alt='mainlogo'></img>
                    </div>
                    <div className='col-12 col-md-4 text-center'>
                        <img src={iristwo} alt='logotwo'></img>
                    </div>
                    <div className='col-12 col-md-4 text-center'>
                        <img src={highstreet} alt='logotwo'></img>
                    </div>
                </section>

                <section className=' row footer_third_section'>
                    <div className=' col-12 col-md-6 col-sm-6'>
                        <div className=' text-start footer_third_one d-flex align-items-center'>
                            <i class="fa-regular fa-envelope me-2" style={{ textDecoration: 'none' }}></i>
                            experience@shivanta.in
                        </div>
                        <div className=' text-start footer_third_two mt-2'>
                            <i class="fa-solid fa-phone me-2"></i>
                            +91 95126 93636
                        </div>
                        <div className='col-12 col-md-8  col-xl-6 text-start footer_third_three mt-2'>
                            <i class="fa-solid fa-location-dot me-2 mt-1"></i>
                            <a className='footer_third_three' href='https://maps.app.goo.gl/V47cfnm4aFNt7kYE6'> Shivanta Group
                                303, Darshanam Arise, Opp. Osia Mall, Gotri-Sevasi Road, Vadodara, Gujarat 390021.</a>

                        </div>
                    </div>

                    <div className='fourth_second  col-12 col-md-6 col-sm-6'>
                        <div>
                            <a href='https://www.facebook.com/profile.php?id=100087780754981'><i class="fa-brands fa-facebook fa-2xl"></i></a>
                        </div>
                        <div className='mt-3'>
                            <a href='https://www.instagram.com/shivantadevelopers/'><i class="fa-brands fa-instagram fa-2xl"></i></a>
                        </div>
                    </div>

                </section>

                {/* <section className='row footer_fourth_section'>
                <div className='fourth_first col-12 col-sm-7 col-md-8 col-lg-7 col-xl-7'>
                    <span
                        onClick={() => {
                            navigate('/aboutus')
                        }}
                    >About us</span>
                    <span
                        onClick={() => {
                            navigate('/contactus')
                        }}

                    >Contact us</span>
                    <span
                        onClick={() => {
                            navigate('/projects/shivantairis')
                        }}>Projects</span>

                </div>



            </section> */}
            </div>
            <div className='footer_last_sec'>
                <div className='footer_title_last'>
                    Copyright <i class="fa-regular fa-copyright"></i>  2024 | Developed By <a className='footer_kodenauts_link' href='https://www.kodenauts.com/'>KodeNauts</a>
                </div>
            </div>

        </footer>
    )
}

export default Footer