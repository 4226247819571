import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import shivantalogo from '../Media/shivantalogo.jpg';


function Downloadbtn() {


    const [mail, setmail] = useState("")
    const [number, setnumber] = useState("")
    const [name, setname] = useState("")


    const [popup, setpopup] = useState(false);

    const popupstyle = {
        borderRadius: "20px",
        backgroundColor: "white",
        boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
        width: "90%",
        height: "max-content",
    }


    return (
        <div>
            <div className='d-flex justify-content-center'>

                <button type="button" class="btn six_download_title" onClick={() => {
                    setpopup(true)
                }}>Download Brochure</button>
            </div>

            <Popup
                className='popup'
                contentStyle={popupstyle}
                modal
                open={popup}
                onClose={() => { setpopup(false) }}
            >
                <div >

                    <div className='d-flex justify-content-end mt-3 me-2'>
                        <i onClick={() => { setpopup(false) }} class="fa-solid fa-xmark fa-2xl"></i>
                    </div>


                    <div className='d-flex justify-content-center w-100'>
                        <div >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ width: '50%' }} src={shivantalogo}></img>
                            </div>
                            <h4 className='text-center'>Contact For More Project Detail</h4>
                            <div className='row'>
                                <div className=' mt-3 col col-12 col-md-6 '>
                                    <input onChange={(e) => {
                                        setname(e.target.value)
                                    }} class="form-control form-control-lg contact_text_field" type="text" placeholder=" Name" aria-label=".form-control-lg example" />
                                </div>
                                <div className=' mt-3 col col-12 col-md-6 '>
                                    <input onChange={(e) => {
                                        setnumber(e.target.value)
                                    }} class="form-control form-control-lg contact_text_field" type="text" placeholder=" Number" aria-label=".form-control-lg example" />
                                </div>
                            </div>

                            <div className=' mt-3'>
                                <input onChange={(e) => {
                                    setmail(e.target.value)
                                }} class="form-control form-control-lg contact_text_field" type="text" placeholder=" Mail" aria-label=".form-control-lg example" />
                            </div>
                            <div className=' mt-3'>
                                <input onChange={(e) => {
                                    setmail(e.target.value)
                                }} class="form-control form-control-lg contact_text_field" type="text" placeholder=" How did you come to know about shiva Lifespaces projects?" aria-label=".form-control-lg example" />
                            </div>


                            <div className='mt-3 mb-3 text-center'>
                                <button type="button" class="btn contact_send_btn" style={{ fontSize: '20px' }} onClick={() => {
                                    if (mail !== "" && name !== "" && number !== "") {
                                        setpopup(false)
                                    }
                                    else {
                                        alert("Add Mail, Name and Number")
                                    }
                                }}>SUBMIT</button>
                            </div>
                        </div>

                    </div>


                </div>

            </Popup>


        </div>
    )
}

export default Downloadbtn