

import React, { useState } from 'react';
import {
  useNavigate,
} from "react-router-dom";
import './Menu.css';



function Menu() {

  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const isOpen = isMenuOpen ? "open2" : "";


  const [first, setfirst] = useState(false);
  const [ongoing, setongoing] = useState(false);
  const [upcoming, setupcoming] = useState(false);


  return (
    <div>
      <section className='header_first_div'>
        <button className={`burger2 ${isOpen}`} onClick={toggleMenu}></button>
        <div className='header_menu_title'>MENU</div>
        <div className={`background2 ${isOpen}`}></div>
        <div className={`menu2 ${isOpen}`} >
          <nav style={{ animationDelay: '9s' }}>
            <a href='' style={{ animationDelay: '0.1s' }} onClick={() => {
              navigate('/')
            }}>Home</a>

            {/* <a class="dropdown" style={{ animationDelay: '0.4s' }}>
              <button class="dropbtn">Projects</button>
              <div class="dropdown-content">
                <a style={{ fontSize: '25px', padding: '3px 9px' }} href="" onClick={() => {
                  navigate('/projects/shivantairis')
                }} >Shivanta Iris</a>
                <a style={{ fontSize: '25px', padding: '3px 9px' }} href="" onClick={() => {
                  navigate('/projects/shivantahighstreet')
                }}>Shivanta Highstreet</a>
                <a style={{ fontSize: '25px', padding: '3px 9px' }} href="" onClick={() => {
                  navigate('/projects/shivantatreeland')
                }}>Shivanta Treeland</a>
              </div>
            </a> */}


            {/* <a style={{ animationDelay: '0.2s' }} >
              <div class="accordion " id="accordionExample">
                <div class="accordion-item acc_main_div">
                  <h2 class="accordion-header " id="headingOne">
                    <button class={`accordion-button collapsed menu_boot_btn ${isOpen}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Projects
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="accordion" id="sub-accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="sub-headingOne">
                            <button class={`accordion-button collapsed menu_boot_btn_two ${isOpen}`} type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Ongoing
                            </button>
                          </h2>
                          <div id="sub-collapseOne" class={`accordion-collapse collapse ${isOpen}`} aria-labelledby="sub-headingOne" data-bs-parent="#sub-accordionExample">
                            <div class="accordion-body">
                              <div className='title_name' onClick={() => {
                                navigate('/projects/shivantairis')
                                setIsMenuOpen(false)
                              }}>Shivanta Iris</div>
                              <div className='title_name' onClick={() => {
                                navigate('/projects/shivantahighstreet')
                                setIsMenuOpen(false)
                              }}>Shivanta Highstreet</div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="sub-headingTwo">
                            <button class={`accordion-button collapsed menu_boot_btn_two ${isOpen}`} type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="true" aria-controls="sub-collapseTwo">
                              UpComing
                            </button>
                          </h2>
                          <div id="sub-collapseTwo" class={`accordion-collapse collapse ${isOpen}`} aria-labelledby="sub-headingTwo" data-bs-parent="#sub-accordionExample">
                            <div class="accordion-body">
                              <div className='title_name' onClick={() => {
                                navigate('/projects/shivantatreeland')
                                setIsMenuOpen(false)
                              }}>Shivanta Treeland</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </a> */}

            <a className='menusub' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              onClick={(() => {
                setfirst(!first)
              })}><div>Projects</div> {first ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>} </a>
            {
              first ? <div style={{ marginLeft: '30px' }}>
                <a onClick={() => {
                  setongoing(!ongoing)
                  setupcoming(false)
                }}>Ongoing </a> <br />
                {
                  ongoing ? <div style={{ marginLeft: '20px' }}>
                    <a className='menusub'  style={{ fontSize: '25px' }} onClick={() => {
                      navigate('/projects/shivantairis')
                      setongoing(false)
                      setIsMenuOpen(false)
                      setfirst(false)
                    }}>- Shivanta Iris</a><br />
                    <a className='menusub'  style={{ fontSize: '25px' }}
                      onClick={() => {
                        navigate('/projects/shivantahighstreet')
                        setongoing(false)
                        setIsMenuOpen(false)
                        setfirst(false)
                      }}>- Shivanta Highstreet</a>
                  </div> : null
                }
                <a onClick={() => {
                  setupcoming(!upcoming)
                  setongoing(false)
                }}>Upcoming</a>

                {
                  upcoming ? <div style={{ marginLeft: '20px' }}>
                    <a className='menusub' style={{ fontSize: '25px' }}
                      onClick={() => {
                        navigate('/projects/shivantatreeland')
                        setongoing(false)
                        setIsMenuOpen(false)
                        setupcoming(!upcoming)
                        setfirst(false)
                      }}>- Shivanta Treeland</a>
                  </div> : null
                }
              </div> : null
            }
















            <a href='' style={{ animationDelay: '0.3s' }} onClick={() => {
              navigate('/aboutus')
            }}>About us</a>
            <a href='' style={{ animationDelay: '0.4s' }} onClick={() => {
              navigate('/contactus')
            }}>Contact us</a>
          </nav>




        </div>





      </section>
      <section className='header_sec_div'>
        <div>
          <a href='https://www.facebook.com/profile.php?id=100087780754981'><i class="fa-brands fa-facebook fa-2xl" ></i></a>
        </div>
        <div>
          <a href='https://www.instagram.com/shivantadevelopers/'><i class="fa-brands fa-instagram fa-2xl"></i></a>
        </div>
        {/* <div>
          <a href='https://www.instagram.com/shivantadevelopers/'><i class="fa-brands fa-linkedin fa-2xl" style={{ color: '#952323' }}></i></a>
        </div> */}

      </section>
    </div>
  )
}

export default Menu