import React from 'react';
import './Aboutus.css';
import shivantalogo from '../../Media/shivantalogo.jpg';
import skyscrapers from '../../Media/skyscrapers.png';
import logotwo from '../../Media/logotwo.png'
import { useNavigate } from 'react-router-dom';
import highstreet from '../../Media/highstreet.jpg'
import shivantacirtificate from '../../Media/shivantacirtificate.jpg';
import shivantairis from '../../Media/iristwo.jpg';
import threeone from '../../Media/iris/threeone.jpeg'




function Aboutus() {

  let navigate = useNavigate();





  return (
    <div>
      <section className='about_section_one'>
        <div className='about_main_logo'>
          <img src={shivantalogo} alt='shivantalogo'
            className='about_logo'
            onClick={() => {
              navigate('/')
            }}></img>
        </div>
        <div className='image_main'>
          <img src={threeone} alt='aboutone' className='about_one_img'></img>
        </div>
      </section>

      {/* <section className='about_section_two'>
        <div className='section_two_title_one'>
          20+ Projects | 1000 + Units | 200000 + Sqft
        </div>
        <div className='section_two_title_two'>
          Creating
        </div>
      </section> */}

      <section className='about_section_three'>
        {/* <div className='section_three_title_one'>
          <div>Landmark</div>
          <div>Since</div>
          <div>Centuries</div>
        </div> */}

        {/* <div className='three_second_main'>
          <div className='hr'></div>
          <div className='three_second_div col-12 col-md-4'>
            <section>2010</section>
            <section>-</section>
            <section>2020</section>
          </div>
          <div className='hr2'></div>
        </div> */}

        <div className='three_third_main'>
          <div className='row third_main_two'>
            <div className=' col-12  col-md-6 text-center'><img src={shivantairis} alt='logotwo' className='about_img_2'></img></div>
            <div className=' col-12  col-md-6 third_title_right'  ><div className='col-md-10'>
              Welcome home to Shivanta Iris where living is an art, and your story begins!
            </div></div>
          </div>
        </div>

        <div className='three_third_main_two'>
          <div className='row third_main_two'>
            <div className=' col-12 col-md-6 text-center'><img src={highstreet} alt='logotwo' className='about_img_2' ></img></div>
            <div className=' col-12 col-md-6 third_title_right'  ><div className='col-md-10'>
              Welcome to Shivanta Highstreet, where business ambitions are realized, and the future of commerce unfolds!
            </div></div>
          </div>
        </div>
      </section>



      <section className='homepage_section_two'>
        {/* <div className='section_two_main_title '>
          Awards <br />
          & Accolades
        </div> */}

        {/* <div className='row align-items-center'>
          <div className='col-12 col-md-7'>
            <img src={awardone} alt='awardone' className='two_award_img'></img>
          </div>
          <div className='col-12 col-md-5'>
            <h5 className='two_title_right_up'>Gujarat Real Estate Award</h5>
            <div className='two_title_right_down'>For Sivanta Iris, 2021</div>
          </div>
        </div> */}

        {/* <div className='row align-items-center award_two'>
          <div className='col-12 col-md-7'>
            <img src={awardtwo} alt='awardtwo' className='two_award_img'></img>
          </div>
          <div className='col-12 col-md-5'>
            <h5 className='two_title_right_up'>Gujarat Real Estate Award</h5>
            <div className='two_title_right_down'>For Sivanta Iris, 2021</div>
          </div>
        </div> */}

        <div className='section_two_main_title '>
          certificates
        </div>
        <div className='container text-center'>
          <img className='cirtificate_image' src={shivantacirtificate}></img>
        </div>

      </section>





    </div>
  )
}

export default Aboutus