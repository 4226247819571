import React, { useState } from 'react';
import './Highstreet.css';
import threefive from '../../Media/highstreet/threefive.jpeg';
import highstreet from '../../Media/highstreet.jpg';
import stone from '../../Media/highstreet/stone.jpeg';
import sttwo from '../../Media/highstreet/sttwo.jpg';
import stthree from '../../Media/highstreet/stthree.jpg';
import stfour from '../../Media/highstreet/stfour.jpeg';
import stfive from '../../Media/highstreet/stfive.jpeg';
import stsix from '../../Media/highstreet/stsix.jpeg';
import Downloadbtn from '../../Components/Downloadbtn';


function Projects() {


    const [Groundfloor, setGroundfloor] = useState([true, false, false, false, true, true, true, true, true, true, false, true, false, false, false, true, false, true]);

    const [Firstfloor, setFirstfloor] = useState([false, true, true, true, true, true, true, false, false, true, true, true, true, true, true, true,]);

    const [secondfloor, setsecondfloor] = useState([true, false, false, false, false, false, true, true, false, false, true, true, true, true, true, true,]);

    const [Thirdfloor, setThirdfloor] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,]);

    const [Fourthfloor, setFourthfloor] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,]);

    const [Fifthfloor, setFifthfloor] = useState([false, true, false, false, true, true, true, true, true, false, false, true, false, false, false, true,]);

    const [Sixthfloor, setSixthfloor] = useState([false, true, true, true, true, true, true, true, true, true, false, true, false, true, true, false, true]);

    const [Seventhfloor, setSeventhfloor] = useState([true, true, true, true, true, true, false, true, true, true, false, true, false, false, true, true, true]);




    const [active, setactive] = useState([true, false, false])






    return (
        <div>
            {/* <section className='project_section_one'>
                <img className='section_one_img' src={stone} alt='projectone'></img>
            </section> */}

            {/* <section>
                <div className='row two_first_div'>
                    <div className=' col-12 col-md-6 col-lg-6 sectiontwo_left_title text-center'>
                        Mediterranean Neighborhood
                    </div>
                    <div className='col-12 col-md-6 col-lg-6 sectiontwo_right_title'>
                        Shivanta Group is primarily synonymous with quality, commitment towards customers, reliability, and excellence in architecture.
                    </div>
                </div>
                <div className='text-center' style={{ backgroundColor: '#d6d0b1' }}>
                    <img src={highstreet} alt='logotwo' className='project_logo_high' ></img>
                </div>
            </section> */}

            <section className='row hight_first_div'>
                <div className='col-md-6 col-12'>
                    <img src={threefive} alt='projecttwo' className='section_three_img'></img>
                </div>
                <div className='col-md-6 col-12 d-flex justify-content-center align-items-center'>
                    <div>
                        <h2 className='mt-3'>Shivanta Highstreet </h2>
                        <h4 className='mt-3'>Shops - Showrooms - Offices </h4>
                        <div className='mt-3 iris_paragraph'>Welcome to Shivanta Highstreet, a pinnacle of commercial excellence where prime location, versatile spaces, and luxurious amenities converge to redefine your business experience. Nestled in the heart of Vadodara, our strategically positioned development ensures unparalleled connectivity to major highways and business hubs. </div>
                        <div className='mt-3 iris_paragraph'>With a range of spaces catering to diverse needs, from stylish showrooms to spacious offices, Shivanta Highstreet offers an ideal backdrop for success. Enjoy the convenience of ample car parking, state-of-the-art security, and a vibrant community atmosphere. Elevate your work environment with terrace garden offices, where nature meets professionalism.</div>
                        <div className='mt-3 iris_paragraph'> Our commitment to a convenient lifestyle extends to a variety of amenities, including dining options, fitness centers, and recreational spaces. Shivanta Highstreet is more than a commercial project; it's an investment in your future. Embrace the perfect blend of functionality, luxury, and growth opportunities. Your success story begins here – where business meets luxury!</div>
                    </div>

                </div>

            </section>

            {/* <section className='project_section_four'>
                <div className='project_four_first_title'>
                    Shops Showrooms and Office
                </div>

                <div className='row project_four_second_div '>
                    <div className='col-12 col-sm-4 col-md-4 four_small_titles'>
                        Children Play
                        Area
                    </div>
                    <div className='col-12 col-sm-4 col-md-4 four_small_titles'>
                        Multiple Recreational Facilities
                    </div>
                </div>

                <div className='row project_four_second_div2'>
                    <div className='col-12 col-sm-4 col-md-4 four_small_titles2'>
                        Swiming Pool
                    </div>
                    <div className='col-12 col-sm-4 col-md-4 four_small_titles_right_2'>
                        In Society Temple
                    </div>
                </div>
            </section> */}


            <section className='iris_gallery section container mt-3'>
                <div className='d-flex'>
                    <div className={active[0] ? 'iris_active_gallery_tab' : 'iris_gallery_tab'}
                        onClick={() => {
                            setactive([true, false, false])
                        }}>Gallery</div>
                    {/* <div className={active[1] ? 'active_gallery_tab' : 'gallery_tab'}
                        onClick={() => {
                            setactive([false, true, false])
                        }}>Floor Plans</div> */}
                    {/* <div className={active[2] ? 'active_gallery_tab' : 'gallery_tab'}
                        onClick={() => {
                            setactive([false, false, true])
                        }}>Walk Through</div> */}
                </div>
                <div className='mt-2'>
                    {
                        active[0] ?
                            <div className='row g-3 iris_gallery_main'>
                                <div className='col-sm-5'><img className='iris_galley_images' src={stone}></img></div>
                                <div className='col-sm-7'><img className='iris_galley_images' src={sttwo}></img></div>
                                <div className='col-sm-4'><img className='iris_galley_images' src={stthree}></img></div>
                                <div className='col-sm-4'><img className='iris_galley_images' src={stfour}></img></div>
                                <div className='col-sm-4'><img className='iris_galley_images' src={stfive}></img></div>
                                <div className='col-sm-3'><img className='iris_galley_images' src={stsix}></img></div>
                            </div>
                            : null
                    }

                    {
                        active[1] ? <div className='row g-3 gallery_main_floor'>
                            {/* <div className='col-sm-4'><img className='galley_images_floor' src={floorone}></img></div> */}
                            {/* <div className='col-sm-4'><img className='galley_images_floor' src={floortwo}></img></div> */}
                            {/* <div className='col-sm-4'><img className='galley_images_floor' src={floorthree}></img></div> */}
                            {/* <div className='col-sm-12'><img className='galley_images_floor' src={floorfour}></img></div> */}

                        </div> : null
                    }

                    {
                        active[2] ? <div className='container'>
                            <iframe
                                src="https://www.youtube.com/embed/Qoz3UV2bbuU?si=gZnp5e0Yke8avMnb">
                            </iframe>
                        </div> : null
                    }

                </div>

            </section>


            <section className='project_section_fifth'>
                <img src={stfour} alt='projectthree' className='section_five_img'></img>
            </section>

            <section className='project_section_six'>
                <div className='six_paragraph col-12 col-md-12 '>
                    We are here to give an experience of a lifetime to every family through infrastructure that is synonymous with supreme quality, beauty, and longevity.
                </div>

                <Downloadbtn />

            </section>

            <section className='highstreet_booking'>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Ground</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Groundfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Groundfloor.slice(9, 18).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>First</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Firstfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Firstfloor.slice(9, 16).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Second</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {secondfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {secondfloor.slice(9, 16).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Third</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Thirdfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Thirdfloor.slice(9, 16).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Fourth</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Fourthfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Fourthfloor.slice(9, 16).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Fifth</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Fifthfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Fifthfloor.slice(9, 16).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ borderRight: '1px solid black', padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Sixth</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Sixthfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Sixthfloor.slice(9, 17).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ padding: '0px 10px' }}>
                    <h2 style={{ borderBottom: '1px solid black', fontWeight:'400' }}><b>Seventh</b> Floor</h2>

                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                        <div>
                            {Seventhfloor.slice(0, 9).map((isactive, index) => (
                                <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                                        {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div>
                            {Seventhfloor.slice(9, 17).map((isactive, index) => (
                                <div key={index + 9} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='main_box me-2'>
                                        <div className={isactive ? 'active' : ''}></div>
                                    </div>

                                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 10}`}>
                                        {` ${index + 10}`}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}

export default Projects;