import './App.css';
import Footer from './Components/Footer/Footer';
// import Header from './Components/Header/Menu';
import { Form, Route, Routes } from 'react-router-dom';
import Homepage from './Components/Homepage/Homepage';
import Iris from './Components/Iris/Iris';
import Aboutus from './Components/Aboutus/Aboutus';
import { useState } from 'react';
import Contactus from './Components/Contactus/Contactus';
import Highstreet from './Components/Highstreet/Highstreet';
import Menu from './Components/Menu/Menu';
import Header from './Components/Header/Header';
import Treeland from './Components/Treeland/Treeland';

function App() {



  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);





  // useEffect(() => {
  //   const scrollProgress = document.getElementById('scroll-progress');
  //   const height =
  //     document.documentElement.scrollHeight - document.documentElement.clientHeight;

  //   window.addEventListener('scroll', () => {
  //     const scrollTop =
  //       document.body.scrollTop || document.documentElement.scrollTop;
  //     scrollProgress.style.width = `${(scrollTop / height) * 100}%`;
  //   });

  // }, [])





  return (
    <div>

      <div className='screen_main_div'>
        <div className='header_main_div'>
          <Menu />
          {/* <Header /> */}

        </div>

        <div className='pages_main_div'>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/aboutus' element={<Aboutus />} />
            <Route path='/contactus' element={<Contactus />} />
            <Route path='/projects/shivantairis' element={<Iris />} />
            <Route path='/projects/shivantahighstreet' element={<Highstreet />} />
            <Route path='/projects/shivantatreeland' element={<Treeland />} />
          </Routes>

          <button
            type='button'
            className='scroll_up_btn btn'
            onClick={scrollToTop}
            style={{ display: visible ? 'inline' : 'none' }} >
            <i class="fa-solid fa-arrow-up "></i>
          </button>
        </div>




        {/* <div id="scroll-progress"></div> */}



      </div>

      <Footer />
    </div>

  );
}

export default App;
