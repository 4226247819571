import React from 'react';
import './Contactus.css';
import { useNavigate } from 'react-router-dom';
import shivantalogo from '../../Media/shivantalogo.jpg';
import skyscrapers from '../../Media/skyscrapers.png';
import stone from '../../Media/highstreet/stone.jpeg';

function Contactus() {


  let navigate = useNavigate();


  return (
    <div>
      <section className='about_section_one'>
        <div className='about_main_logo'>
          <img src={shivantalogo} alt='shivantalogo'
            className='about_logo'
            onClick={() => {
              navigate('/')
            }}></img>
        </div>
        <div className='image_main'>
          <img src={stone} alt='aboutone' className='about_one_img'></img>
        </div>
      </section>

      <section className='contact_section_two '>
        <div className='mt-3 contact_get_title'>Get In Touch</div>
        <div className='col-11 col-md-9 col-lg-6 mt-3'>
          <input class="form-control form-control-lg contact_text_field" type="text" placeholder="Your Mail" aria-label=".form-control-lg example" />
        </div>
        <div className='col-11 col-md-9 col-lg-6 mt-3 '>
          <input class="form-control form-control-lg contact_text_field" type="text" placeholder="Your Name" aria-label=".form-control-lg example" />
        </div>
        <div className='col-11 col-md-9 col-lg-6 mt-3 '>
          <input class="form-control form-control-lg contact_text_field" type="text" placeholder="Your Number" aria-label=".form-control-lg example" />
        </div>
        <div className='mt-3 mb-3'>
          <button type="button" class="btn contact_send_btn">SUBMIT</button>
        </div>

      </section>
    </div>
  )
}

export default Contactus