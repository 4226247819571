import React, { useState } from 'react';
import './Iris.css';
import projectone from '../../Media//iris/projectone.png';
import projecttwo from '../../Media/iris/threeone.jpeg';
import irispool from '../../Media/iris/irispool.jpeg';
import logotwo from '../../Media/logotwo.png';
import threethree from '../../Media/iris/threethree.jpg';
import irisinner from '../../Media/iris/irisinner.jpeg';
import irisgarden from '../../Media/iris/irisgarden.jpeg';
import irisfront from '../../Media/iris/irisfront.jpeg';
import floorone from '../../Media/iris/irisfloorone.jpg';
import floortwo from '../../Media/iris/irirfloortwo.jpg';
import floorthree from '../../Media/iris/irisfloorthree.jpg';
import floorfour from '../../Media/iris/irisfloorfour.jpg';
import irisbrochure from '../../Media/irisbrochure.pdf'
import Downloadbtn from '../Downloadbtn';


function Projects() {

  const [Groundfloor, setGroundfloor] = useState([false, true, true, true, false, true, true, true, true]);
  const [Firstfloor, setFirst] = useState([false, true, true, true, true, false]);
  const [Secondfloor, setSecondfloor] = useState([false, false, false, true, true, true]);



  const [active, setactive] = useState([true, false, false])


  const resi = [
    {
      isactive: true,
      title: 301
    },
    {
      isactive: false,
      title: 401
    },
    {
      isactive: false,
      title: 501
    },
    {
      isactive: true,
      title: 601
    },
    {
      isactive: false,
      title: 701
    },
    {
      isactive: true,
      title: 801
    },
    {
      isactive: false,
      title: 901
    },
    {
      isactive: false,
      title: 1001
    },
    {
      isactive: true,
      title: 1101
    },
    {
      isactive: true,
      title: 302
    },
    {
      isactive: true,
      title: 402
    },
    {
      isactive: true,
      title: 502
    },
    {
      isactive: true,
      title: 602
    },
    {
      isactive: true,
      title: 702
    },
    {
      isactive: true,
      title: 802
    },
    {
      isactive: true,
      title: 902
    },
    {
      isactive: true,
      title: 1002
    },
    {
      isactive: true,
      title: 1102
    },
    {
      isactive: true,
      title: 303
    },
    {
      isactive: false,
      title: 403
    },
    {
      isactive: true,
      title: 503
    },
    {
      isactive: true,
      title: 603
    },
    {
      isactive: true,
      title: 703
    },
    {
      isactive: false,
      title: 803
    },
    {
      isactive: true,
      title: 903
    },
    {
      isactive: true,
      title: 1003
    },
    {
      isactive: true,
      title: 1103
    },
    {
      isactive: true,
      title: 1201
    },
  ]


  return (
    <div>
      {/* <section className='iris_project_section_one'>
        <img className='iris_section_one_img' src={projectone} alt='projectone'></img>
      </section> */}

      {/* <section>
        <div className='row iris_two_first_div'>
          <div className=' col-12 col-md-6 col-lg-6 iris_sectiontwo_left_title text-center'>
            Mediterranean Neighborhood
          </div>
          <div className='col-12 col-md-6 col-lg-6 iris_sectiontwo_right_title'>
            Shivanta Group is primarily synonymous with quality, commitment towards customers, reliability, and excellence in architecture.
          </div>
        </div>
        <div className='text-center' style={{ backgroundColor: '#d6d0b1' }}>
          <img src={logotwo} alt='logotwo' className='iris_project_logo' ></img>
        </div>
      </section> */}

      <section className='iris_section_first row'>
        <div className=' col-md-6 col-12 text-center'>
          <img src={projecttwo} alt='projecttwo' className='iris_section_three_img '></img>
        </div>

        <div className='col-md-6 col-12 d-flex justify-content-center align-items-center'>
          <div>
            <h2 className='mt-3'>Shivanta Iris </h2>
            <h4 className='mt-3'>3BHK Lifestyle Apartments & Shops </h4>
            <div className='mt-3 iris_paragraph'>Welcome to Shivanta Iris, an exclusive residential haven where luxury, convenience, and sophistication converge in perfect harmony. Nestled in a prime and sought-after location, this intimate community is meticulously designed for only 28 families, ensuring a sense of exclusivity and tranquillity. </div>
            <div className='mt-3 iris_paragraph'>Experience the convenience of ample and well-designed car parking, bid farewell to parking woes, and embrace a stress-free living experience. Indulge in opulence with our array of luxurious amenities, from well-manicured gardens to state-of-the-art fitness centres. Shivanta Iris is not just a home; it's an intimate community fostering camaraderie and warmth. Step into the future with our smart homes, seamlessly integrating cutting-edge technology with daily living. Your sanctuary in the city, Shivanta Iris is where luxury meets practicality, and exclusivity meets convenience. Welcome to a residence that transcends expectations, your home in the heart of luxury and comfort.</div>

            <div className='mt-3'>
              <a className='btn_360' href='https://dev.d3402oprqcvtup.amplifyapp.com/'>Experience 360 View </a>
            </div>
          </div>


        </div>


      </section>





      {/* <section className='iris_project_section_four'>
        <div className='iris_project_four_first_title'>
          3 BHK Lifestyle Apartment <br />
          & Shops
        </div>

        <div className='row iris_project_four_second_div '>
          <div className='col-12 col-sm-4 col-md-4 iris_four_small_titles'>
            Children Play
            Area
          </div>
          <div className='col-12 col-sm-4 col-md-4 iris_four_small_titles'>
            Multiple Recreational Facilities
          </div>
        </div>

        <div className='row iris_project_four_second_div2'>
          <div className='col-12 col-sm-4 col-md-4 iris_four_small_titles2'>
            Swiming Pool
          </div>
          <div className='col-12 col-sm-4 col-md-4 iris_four_small_titles_right_2'>
            In Society Temple
          </div>
        </div>
      </section> */}

      <section className='iris_gallery section container mt-3'>
        <div className='iris_gallery_sec_two'>
          <div className={active[0] ? 'iris_active_gallery_tab' : 'iris_gallery_tab'}
            onClick={() => {
              setactive([true, false, false])
            }}>Gallery</div>
          <div className={active[1] ? 'iris_active_gallery_tab' : 'iris_gallery_tab'}
            onClick={() => {
              setactive([false, true, false])
            }}>Floor Plans</div>
          <div className={active[2] ? 'iris_active_gallery_tab' : 'iris_gallery_tab'}
            onClick={() => {
              setactive([false, false, true])
            }}>Walk Through</div>
        </div>
        <div className='mt-2'>
          {
            active[0] ? <div className='row g-3 iris_gallery_main'>
              <div className='col-sm-5'><img className='iris_galley_images' src={threethree}></img></div>
              <div className='col-sm-7'><img className='iris_galley_images' src={irisinner}></img></div>
              <div className='col-sm-5'><img className='iris_galley_images' src={irispool}></img></div>
              <div className='col-sm-3'><img className='iris_galley_images' src={irisfront}></img></div>
              <div className='col-sm-4'><img className='iris_galley_images' src={irisgarden}></img></div>
            </div> : null
          }

          {
            active[1] ? <div className='row g-3 iris_gallery_main_floor'>
              <div className='col-sm-4'><img className='iris_galley_images_floor' src={floorone}></img></div>
              <div className='col-sm-4'><img className='iris_galley_images_floor' src={floortwo}></img></div>
              <div className='col-sm-4'><img className='iris_galley_images_floor' src={floorthree}></img></div>
              <div className='col-sm-12'><img className='iris_galley_images_floor' src={floorfour}></img></div>
            </div>
              : null
          }

          {
            active[2] ? <div className='container'>
              <iframe style={{ width: '100%', aspectRatio: '16/9' }}
                src="https://www.youtube.com/embed/Qoz3UV2bbuU?si=gZnp5e0Yke8avMnb">
              </iframe>
            </div> : null
          }

        </div>

      </section>

      <section className='iris_project_section_fifth'>
        <img src={irispool} alt='projectthree' className='iris_section_five_img'></img>
      </section>

      <section className='iris_project_section_six'>
        <div className='six_paragraph col-12 col-md-12 '>
          We are here to give an experience of a lifetime to every family through infrastructure that is synonymous with supreme quality, beauty, and longevity.
        </div>
        <Downloadbtn />

      </section>


      <section className='container iris_booking'>

        <div style={{ borderRight: '2px solid #FFD700' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h3 className='text-center' style={{ borderBottom: '1px solid #FFD700' }}>COMMERCIAL</h3></div>
          <div className='d-flex justify-content-evenly'>
            <div className='p-3' style={{ borderRight: '1px solid black' }}>
              <h5>GROUND</h5>
              <div>
                {Groundfloor.slice(0, 9).map((isactive, index) => (
                  <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='main_box me-2'>
                      <div className={isactive ? 'active' : ''}></div>
                    </div>

                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                      {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className='p-3' style={{ borderRight: '1px solid black' }}>
              <h5>FIRST</h5>
              <div>
                {Groundfloor.slice(0, 9).map((isactive, index) => (
                  <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='main_box me-2'>
                      <div className={isactive ? 'active' : ''}></div>
                    </div>

                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                      {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className='p-3'>
              <h5>SECOND</h5>
              <div>
                {Groundfloor.slice(0, 9).map((isactive, index) => (
                  <div key={index} className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='main_box me-2'>
                      <div className={isactive ? 'active' : ''}></div>
                    </div>

                    <label style={{ fontSize: '25px' }} htmlFor={`${index + 1}`}>
                      {` ${index + 1 < 10 ? '0' : ''}${index + 1}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>


          </div>
        </div>

        <div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h3 className='text-center' style={{ borderBottom: '1px solid #FFD700' }}>RESIDENTIAL</h3></div>
          <div className='ff'>
            {resi.map((data) => (
              <div className='d-flex' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='main_box me-2'>
                  <div className={data.isactive ? 'active' : ''}></div>
                </div>

                <label style={{ fontSize: '25px' }} >
                  {data.title}
                </label>
              </div>
            ))
            }

          </div>


        </div>

      </section>

    </div>
  )
}

export default Projects;