import React from 'react';
import './Homepage.css';
import shivantalogo from '../../Media/shivantalogo.jpg';
import balancescale from '../../Media/balancescale.png';
import customersati from '../../Media/customersati.png';
import handshake from '../../Media/handshake.png';
import transparency from '../../Media/transparency.png';
import threeone from '../../Media//iris/threeone.jpeg';
import threethree from '../../Media/iris/threethree.jpg';
import irispool from '../../Media/iris/irispool.jpeg';
import stsix from '../../Media/highstreet/stsix.jpeg';
import threefive from '../../Media/highstreet/threefive.jpeg';
import logotwo from '../../Media/logotwo.png';
import { useNavigate } from 'react-router-dom';
import skyscrapers from '../../Media/skyscrapers.png';
import { Swiper, SwiperSlide } from 'swiper/react';

// Default theme
import '@splidejs/react-splide/css';

// or other themes
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';



import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';



function Homepage() {

  let navigate = useNavigate();

  const details = [
    {
      cap: "I own a apartment at Shivanta Iris and I am one of the delighted customer. I am thoroughly impressed with Shivanta Group's professional approach and apartment planning.The team understand customer's requirements perfectly and then finding them the investment options that fits their liking. I truly recommend Shivanta Group for their extremely good work and commitment",
      name: "Ms. Shashi Sharma"
    },
    {
      cap: "The Team of Shivanta Group is very professional and Heartful in good measure, with a result-oriented approach and considers every aspect from both the buyer's and the developer's perspectives.",
      name: "Mr Rajesh Dhankani"
    },
    {
      cap: "While scouting for a Premium Commercial property I came across Shivanta Highstreet that gives maximum visibility, approachable and at a very well connected location that fulfills complete business requirement. l highly recommend Shivanta Group if you are looking for your dream property.",
      name: "Mr. Dilip Sharma"
    },

  ]

  const cards = [
    {
      image: customersati,
      title: "Customer Satisfaction",
      caption: "Exceeding expectations, one happy homeowner at a time.",
    },

    {
      image: balancescale,
      title: "Ethical Practices",
      caption: "Building dreams on a foundation of integrity.",
    },
    {
      image: transparency,
      title: "Transparency",
      caption: "Clear as crystal – because your trust is our foundation.",
    },
    {
      image: handshake,
      title: "Commitment",
      caption: "More than promises – it's our unwavering commitment to your vision.",
    },


  ]





  return (

    <div className='homepagee_main_div'>

      <section style={{ background: '#8e2c2c' }}>
        <div className='homepage_main_div_second'>
          <section className='homepage_main_sec'>
            <img src={shivantalogo} alt='shivantalogo' className='home_main_logo'
              onClick={() => {
                navigate('/')
              }}></img>
          </section>
          <section className='home_section_one '>
            <div className='col-12 col-sm-8 col-md-11 col-lg-7 text-start'>
              <div className='sec_one_main_title'>
                <div>
                  A reflection<br /> of your status
                </div>
              </div>
              <span className='sec_one_main_para'>
                Embark on a journey to find the home that suits your lifestyle. From cozy apartments to luxurious workspaces, we have a diverse range of properties to cater to your unique needs. Our expert team is here to guide you every step of the way, ensuring a seamless and enjoyable home-buying experience.
              </span>
            </div>
          </section>
        </div>
      </section >

      <section className='homepage_section_two2 '>
        <div className='row'>
          <div className='section_two_left2 col-12 col-md-12 col-lg-8'>
            <img src={threethree} alt='skyscraper'></img>
          </div>

          <div className='section_two_right2 col-12 col-md-12 col-lg-4'>
            <div className='two_right_title2'>
              Where Dreams Find a Home!
            </div>
            <div className='two_right_paragraph2'>
              Discover the epitome of luxurious living and unparalleled comfort at Sivaa Lifespaces. As your trusted partner in real estate, we invite you to explore a world where every brick is laid with precision, and every space is designed with your aspirations in mind.
            </div>
            <div className='two_right_paragraph2 mt-3'>
              With years of expertise and a commitment to customer satisfaction, Sivaa Lifespaces is your reliable partner in the journey to find your dream home or perfect business space. Our team of professionals is dedicated to guiding you through every step of the process.
            </div>
            {/* <div className='two_right_works2'>
              <div className='works_first2'>
                <div className='work_title'>20+ Projects Completed</div>
                <div className='work_icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg></div>
              </div>
              <div className='works_first2'>
                <div className='work_title'>1000+ Units</div>
                <div><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg></div>
              </div>
              <div className='works_first2'>
                <div className='work_title'>2 Lakhs+ Sq. Ft. Area</div>
                <div><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg></div>
              </div>
            </div> */}
            {/* <div className='right_about_title' onClick={() => {
              navigate('/aboutus')
            }}>ABOUT US</div> */}
          </div>
        </div>
      </section>

      {/* <section className='homepage_section_three'>

        <div className='three_sec_one'>
          <section>
            <div className='three_sec_one_main_title'>
              Our <br />
              Projects
            </div>
            <div className='three_one_line'></div>
          </section>
          <section>
            <img src={threeone} className='three_right_img' alt='threeone'></img>
          </section>
          <img src={logotwo} className='logo_two' alt='logotwo'></img>
        </div>

        <section className='three_sec_two row'>
          <img src={irispool} alt='threetwo' className='col-12 col-md-4' ></img>
          <img src={threethree} alt='threethree' className='col-12 col-md-4'></img>
          <div className='col-12 col-md-4 three_sect_two_last_title'>
            3 BHK
            Lifestyle
            Apartment
            & Shops

            <div onClick={() => {
              navigate('/projects')
            }}>
              View More
            </div>
          </div>
        </section>

        <section className='three_section_two row' >
          <div className='col-12 col-md-4'>
            <div className='three_two_title'>
              SHIVANTA
              HIGHSTREET
            </div>
            <img src={stsix} className='three_two_four_imag' alt='threefour'></img>
          </div>

          <div className='col-12 col-md-8'>
            <img src={threefive} className='three_two_five_img' alt='threefive'></img>
            <div className='three_shop_main_div'>
              <div className='three_two_shops_title col-md-8'>
                Shops | Showrooms | Offices
              </div>
              <div className='col-md-4  three_view_title'
                onClick={() => {
                  navigate('/projects')
                }}>
                View More
              </div>
            </div>
          </div>
        </section>
      </section> */}

      <section style={{ background: '#952323' }}>
        <section className='homepage_section_three'>
          <div className='row text-center'>
            <div className=' col-12 col-md-4 home_title_name'
              onClick={(() => {
                navigate('/projects/shivantairis')
              })}>Shivanta Iris</div>
            <div className=' col-12 col-md-4 home_title_name' style={{fontWeight:'700'}}>Our Projects</div>
            <div className=' col-12 col-md-4 home_title_name'
              onClick={(() => {
                navigate('/projects/shivantahighstreet')
              })}>Shivanta Highstreet</div>
          </div>
        </section>
      </section>


      <section className='homepage_section_four'>
        <div className='four_first_title_main'>
          <div className='four_first_title col-12 col-sm-11 col-md-11 col-lg-8 text-center'>
          Sivaa Lifespaces is primarily synonymous with quality, commitment towards customers, reliability, and excellence in architecture.
          </div>
        </div>
        <div className=' four_second_div  '>
          <div className='second_2'>

            {
              cards.map((data2) => (
                <div className='main_card'>
                  <div className='photo_div'>
                    <img className='main_photo' src={data2.image} alt="" />
                  </div>
                  <div className='card_detail'>
                    <div className='card_name'>{data2.title}</div>
                    <div className='card_profession'>{data2.caption}</div>
                  </div>
                </div>
              ))

            }




            {/* <div className='mt-3'>
              <div className='four_second_div_main_title'>Customer Satisfaction</div>
              <div className='caption_home'>"Exceeding expectations, one happy homeowner at a time."</div>
            </div>
            <div className='mt-3'>
              <div className='four_second_div_main_title'>Ethical Practices</div>
              <div className='caption_home'>"Building dreams on a foundation of integrity."
              </div>
            </div>
            <div className='mt-3'>
              <div className='four_second_div_main_title'>Transparency</div>
              <div className='caption_home'>"Clear as crystal – because your trust is our foundation."
              </div>
            </div>
            <div className='mt-3'>
              <div className='four_second_div_main_title'>Commitment</div>
              <div className='caption_home'>"More than promises – it's our unwavering commitment to your vision."
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className='four_first_title_main'>
          <div className='four_first_title col-12 col-sm-11 col-md-11 col-lg-8 text-center'>
            We are here to give an experience of a lifetime to every family through infrastructure that is synonymous with supreme quality, beauty, and longevity.

          </div>
        </div> */}


      </section>


      <section className=' mb-5 pt-5 container'>
        <div className='row'>
          <div className='col col-md-12 col-lg-5' style={{ padding: '30px' }}>
            <h1 className='testi_left_side'>Feedback and <br></br> experiences of our <br></br>Satisfied Customers</h1>
            <div className='mt-5'>
              <div className='testi_div_two'>
                <i class="fa-solid fa-check fa-xl"></i>
                <span>Customer Satisfaction</span>
              </div>
              <div className='testi_div_two'>
                <i class="fa-solid fa-check fa-xl"></i>
                <span>Quality Assurance</span>
              </div>
              <div className='testi_div_two'>
                <i class="fa-solid fa-check fa-xl"></i>
                <span>Trust and Reliability</span>
              </div>
            </div>
          </div>

          <div className='col col-md-12 col-lg-7'>
            <Splide hasTrack={false} aria-label="..."

              options={{
                rewind: true,
                gap: '1rem',
                breakpoints: {

                }
              }}
            >
              <SplideTrack className='splide_track' >
                {
                  details.map((data) => (
                    <SplideSlide className='splide_detail_div'><div className='testi_cap'>"{data.cap}"</div>
                      <div className='testi_rev_name'>{data.name}</div></SplideSlide>
                  ))
                }
              </SplideTrack>

              <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev carousal_btn"><i class="fa-solid fa-chevron-left fa-xl"></i></button>
                <button className="splide__arrow splide__arrow--next carousal_btn "><i class="fa-solid fa-chevron-right fa-xl"></i></button>
              </div>
            </Splide>
          </div>
        </div>



      </section>

    </div>
  )
}

export default Homepage